// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  /** https: //color-hex.org/color/bd4639 **/
  --ion-color-primary: #BD4639;
  --ion-color-primary-rgb: 189, 70, 57;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #97382D;
  --ion-color-primary-tint: #CA6B60;

  /** secondary **/
  --ion-color-secondary: #895a4d;
  --ion-color-secondary-rgb: 137, 90, 77;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #6d483d;
  --ion-color-secondary-tint: #a07a70;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2FD36F;
  --ion-color-success-rgb: 47, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #25A858;
  --ion-color-success-tint: #58DB8B;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** video **/
  --ion-color-video: #dc4a38;
  --ion-color-video-rgb: 220, 74, 56;
  --ion-color-video-contrast: #ffffff;
  --ion-color-video-contrast-rgb: 255, 255, 255;
  --ion-color-video-shade: #b03b2c;
  --ion-color-video-tint: #e36e5f;

  /** audio **/
  --ion-color-audio: #00592C;
  --ion-color-audio-rgb: 0, 89, 44;
  --ion-color-audio-contrast: #ffffff;
  --ion-color-audio-contrast-rgb: 255, 255, 255;
  --ion-color-audio-shade: #004723;
  --ion-color-audio-tint: #327a56;

  /** tips **/
  --ion-color-tips: #F97C2C;
  --ion-color-tips-rgb: 249, 124, 44;
  --ion-color-tips-contrast: #ffffff;
  --ion-color-tips-contrast-rgb: 255, 255, 255;
  --ion-color-tips-shade: #c76323;
  --ion-color-tips-tint: #fa9656;

  /** play **/
  --ion-color-play: #00592C;
  --ion-color-play-rgb: 0, 89, 44;
  --ion-color-play-contrast: #ffffff;
  --ion-color-play-contrast-rgb: 255, 255, 255;
  --ion-color-play-shade: #004723;
  --ion-color-play-tint: #327a56;

  /** stop **/
  --ion-color-stop: #8C260C;
  --ion-color-stop-rgb: 140, 38, 12;
  --ion-color-stop-contrast: #ffffff;
  --ion-color-stop-contrast-rgb: 255, 255, 255;
  --ion-color-stop-shade: #701e09;
  --ion-color-stop-tint: #a3513c;

  /** loop **/
  --ion-color-loop: #3b5998;
  --ion-color-loop-rgb: 59, 89, 152;
  --ion-color-loop-contrast: #ffffff;
  --ion-color-loop-contrast-rgb: 255, 255, 255;
  --ion-color-loop-shade: #2f4779;
  --ion-color-loop-tint: #627aac;

  /** products **/
  --ion-color-products: #895a4d;
  --ion-color-products-rgb: 137, 90, 77;
  --ion-color-products-contrast: #ffffff;
  --ion-color-products-contrast-rgb: 255, 255, 255;
  --ion-color-products-shade: #6d483d;
  --ion-color-products-tint: #a07a70;

  /** protip **/
  --ion-color-protip: #3b5998;
  --ion-color-protip-rgb: 59, 89, 152;
  --ion-color-protip-contrast: #ffffff;
  --ion-color-protip-contrast-rgb: 255, 255, 255;
  --ion-color-protip-shade: #2f4779;
  --ion-color-protip-tint: #627aac;

  /** protipBackground **/
  --ion-color-protipBackground: #DCDCDC;
  --ion-color-protipBackground-rgb: 220, 220, 220;
  --ion-color-protipBackground-contrast: #161616;
  --ion-color-protipBackground-contrast-rgb: 22, 22, 22;
  --ion-color-protipBackground-shade: #b0b0b0;
  --ion-color-protipBackground-tint: #e3e3e3;

  /** specialScenerio **/
  --ion-color-specialScenerio: #FECB97;
  --ion-color-specialScenerio-rgb: 254, 203, 151;
  --ion-color-specialScenerio-contrast: #161616;
  --ion-color-specialScenerio-contrast-rgb: 22, 22, 22;
  --ion-color-specialScenerio-shade: #cba278;
  --ion-color-specialScenerio-tint: #fed5ab;

  /** specialScenerioBackground **/
  --ion-color-specialScenerioBackground: #DCDCDC;
  --ion-color-specialScenerioBackground-rgb: 220, 220, 220;
  --ion-color-specialScenerioBackground-contrast: #161616;
  --ion-color-specialScenerioBackground-contrast-rgb: 22, 22, 22;
  --ion-color-specialScenerioBackground-shade: #b0b0b0;
  --ion-color-specialScenerioBackground-tint: #e3e3e3;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #BD4639;
    --ion-color-primary-rgb: 189, 70, 57;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #97382D;
    --ion-color-primary-tint: #CA6B60;

    --ion-color-secondary: #895a4d;
    --ion-color-secondary-rgb: 137, 90, 77;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #6d483d;
    --ion-color-secondary-tint: #a07a70;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /** video **/
    --ion-color-video: #dc4a38;
    --ion-color-video-rgb: 220, 74, 56;
    --ion-color-video-contrast: #ffffff;
    --ion-color-video-contrast-rgb: 255, 255, 255;
    --ion-color-video-shade: #b03b2c;
    --ion-color-video-tint: #e36e5f;

    /** audio **/
    --ion-color-audio: #00592C;
    --ion-color-audio-rgb: 0, 89, 44;
    --ion-color-audio-contrast: #ffffff;
    --ion-color-audio-contrast-rgb: 255, 255, 255;
    --ion-color-audio-shade: #004723;
    --ion-color-audio-tint: #327a56;

    /** tips **/
    --ion-color-tips: #F97C2C;
    --ion-color-tips-rgb: 249, 124, 44;
    --ion-color-tips-contrast: #ffffff;
    --ion-color-tips-contrast-rgb: 255, 255, 255;
    --ion-color-tips-shade: #c76323;
    --ion-color-tips-tint: #fa9656;

    /** play **/
    --ion-color-play: #00592C;
    --ion-color-play-rgb: 0, 89, 44;
    --ion-color-play-contrast: #ffffff;
    --ion-color-play-contrast-rgb: 255, 255, 255;
    --ion-color-play-shade: #004723;
    --ion-color-play-tint: #327a56;

    /** stop **/
    --ion-color-stop: #8C260C;
    --ion-color-stop-rgb: 140, 38, 12;
    --ion-color-stop-contrast: #ffffff;
    --ion-color-stop-contrast-rgb: 255, 255, 255;
    --ion-color-stop-shade: #701e09;
    --ion-color-stop-tint: #a3513c;

    /** loop **/
    --ion-color-loop: #3b5998;
    --ion-color-loop-rgb: 59, 89, 152;
    --ion-color-loop-contrast: #ffffff;
    --ion-color-loop-contrast-rgb: 255, 255, 255;
    --ion-color-loop-shade: #2f4779;
    --ion-color-loop-tint: #627aac;

    /** products **/
    --ion-color-products: #895a4d;
    --ion-color-products-rgb: 137, 90, 77;
    --ion-color-products-contrast: #ffffff;
    --ion-color-products-contrast-rgb: 255, 255, 255;
    --ion-color-products-shade: #6d483d;
    --ion-color-products-tint: #a07a70;

    /** protip **/
    --ion-color-protip: #3b5998;
    --ion-color-protip-rgb: 59, 89, 152;
    --ion-color-protip-contrast: #ffffff;
    --ion-color-protip-contrast-rgb: 255, 255, 255;
    --ion-color-protip-shade: #2f4779;
    --ion-color-protip-tint: #627aac;

    /** protipBackground **/
    --ion-color-protipBackground: #DCDCDC;
    --ion-color-protipBackground-rgb: 220, 220, 220;
    --ion-color-protipBackground-contrast: #161616;
    --ion-color-protipBackground-contrast-rgb: 22, 22, 22;
    --ion-color-protipBackground-shade: #b0b0b0;
    --ion-color-protipBackground-tint: #e3e3e3;

    /** specialScenerio **/
    --ion-color-specialScenerio: #FECB97;
    --ion-color-specialScenerio-rgb: 254, 203, 151;
    --ion-color-specialScenerio-contrast: #161616;
    --ion-color-specialScenerio-contrast-rgb: 22, 22, 22;
    --ion-color-specialScenerio-shade: #cba278;
    --ion-color-specialScenerio-tint: #fed5ab;

    /** specialScenerioBackground **/
    --ion-color-specialScenerioBackground: #DCDCDC;
    --ion-color-specialScenerioBackground-rgb: 220, 220, 220;
    --ion-color-specialScenerioBackground-contrast: #161616;
    --ion-color-specialScenerioBackground-contrast-rgb: 22, 22, 22;
    --ion-color-specialScenerioBackground-shade: #b0b0b0;
    --ion-color-specialScenerioBackground-tint: #e3e3e3;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

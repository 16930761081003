/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-color-video {
  --ion-color-base: var(--ion-color-video) !important;
  --ion-color-base-rgb: var(--ion-color-video-rgb) !important;
  --ion-color-contrast: var(--ion-color-video-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-video-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-video-shade) !important;
  --ion-color-tint: var(--ion-color-video-tint) !important;
}

.ion-color-audio {
  --ion-color-base: var(--ion-color-audio) !important;
  --ion-color-base-rgb: var(--ion-color-audio-rgb) !important;
  --ion-color-contrast: var(--ion-color-audio-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-audio-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-audio-shade) !important;
  --ion-color-tint: var(--ion-color-audio-tint) !important;
}

.ion-color-tips {
  --ion-color-base: var(--ion-color-tips) !important;
  --ion-color-base-rgb: var(--ion-color-tips-rgb) !important;
  --ion-color-contrast: var(--ion-color-tips-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-tips-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-tips-shade) !important;
  --ion-color-tint: var(--ion-color-tips-tint) !important;
}

.ion-color-play {
  --ion-color-base: var(--ion-color-play) !important;
  --ion-color-base-rgb: var(--ion-color-play-rgb) !important;
  --ion-color-contrast: var(--ion-color-play-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-play-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-play-shade) !important;
  --ion-color-tint: var(--ion-color-play-tint) !important;
}

.ion-color-stop {
  --ion-color-base: var(--ion-color-stop) !important;
  --ion-color-base-rgb: var(--ion-color-stop-rgb) !important;
  --ion-color-contrast: var(--ion-color-stop-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-stop-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-stop-shade) !important;
  --ion-color-tint: var(--ion-color-stop-tint) !important;
}

.ion-color-loop {
  --ion-color-base: var(--ion-color-loop) !important;
  --ion-color-base-rgb: var(--ion-color-loop-rgb) !important;
  --ion-color-contrast: var(--ion-color-loop-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-loop-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-loop-shade) !important;
  --ion-color-tint: var(--ion-color-loop-tint) !important;
}

.ion-color-products {
  --ion-color-base: var(--ion-color-products) !important;
  --ion-color-base-rgb: var(--ion-color-products-rgb) !important;
  --ion-color-contrast: var(--ion-color-products-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-products-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-products-shade) !important;
  --ion-color-tint: var(--ion-color-products-tint) !important;
}

.ion-color-protip {
  --ion-color-base: var(--ion-color-protip) !important;
  --ion-color-base-rgb: var(--ion-color-protip-rgb) !important;
  --ion-color-contrast: var(--ion-color-protip-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-protip-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-protip-shade) !important;
  --ion-color-tint: var(--ion-color-protip-tint) !important;
}

.ion-color-protipBackground {
  --ion-color-base: var(--ion-color-protipBackground) !important;
  --ion-color-base-rgb: var(--ion-color-protipBackground-rgb) !important;
  --ion-color-contrast: var(--ion-color-protipBackground-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-protipBackground-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-protipBackground-shade) !important;
  --ion-color-tint: var(--ion-color-protipBackground-tint) !important;
}

.ion-color-specialScenerio {
  --ion-color-base: var(--ion-color-specialScenerio) !important;
  --ion-color-base-rgb: var(--ion-color-specialScenerio-rgb) !important;
  --ion-color-contrast: var(--ion-color-specialScenerio-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-specialScenerio-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-specialScenerio-shade) !important;
  --ion-color-tint: var(--ion-color-specialScenerio-tint) !important;
}

.ion-color-specialScenerioBackground {
  --ion-color-base: var(--ion-color-specialScenerioBackground) !important;
  --ion-color-base-rgb: var(--ion-color-specialScenerioBackground-rgb) !important;
  --ion-color-contrast: var(--ion-color-specialScenerioBackground-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-specialScenerioBackground-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-specialScenerioBackground-shade) !important;
  --ion-color-tint: var(--ion-color-specialScenerioBackground-tint) !important;
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-specialScenerioBackground) !important;
  --ion-color-base-rgb: var(--ion-color-specialScenerioBackground-rgb) !important;
  --ion-color-contrast: var(--ion-color-specialScenerioBackground-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-specialScenerioBackground-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-specialScenerioBackground-shade) !important;
  --ion-color-tint: var(--ion-color-specialScenerioBackground-tint) !important;
}

.logo {
  width: 40%;
  max-height: 35%;
  vertical-align: middle;
  margin-top: 3rem;
}

.img-logo {
  width: 30%;
}

ion-title {
  font-weight: bold;
  font-size: x-large;
}

::ng-deep .item-header {
  font-weight: bold !important;
}

.media-card {
  max-width: 576px;
  position: relative;
  padding: 0;
}

.icon-overlay {
  width: 100%;
  height: 70px;
  position: absolute;
  z-index: 99;
  top: 60px;
  color: #fff;
  text-align: center;
}

.icon-overlay>ion-icon {
  font-size: 120px;
}

.icn-spinner {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
